import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faPhoneAlt,
  faComments,
} from "@fortawesome/free-solid-svg-icons";
import logoSrc from "../assets/images/logo.png";

const ContactPage = () => {
  const navigate = useNavigate();

  const handleCallClick = () => {
    window.location.href = "tel:+46760374616";
  };

  return (
    <Container>
      <ContentWrapper>
        <Logo src={logoSrc} alt="Logo" />
        <CardContainer>
          <ContactCard onClick={() => navigate("/chat")}>
            <IconContainer>
              <FontAwesomeIcon icon={faComments} size="2x" />
            </IconContainer>
            <TextContainer>
              <CardTitle>Chat with Us</CardTitle>
              <CardDescription>
                Get instant support via our chat service.
              </CardDescription>
            </TextContainer>
          </ContactCard>

          <ContactCard onClick={() => navigate("/email")}>
            <IconContainer>
              <FontAwesomeIcon icon={faEnvelope} size="2x" />
            </IconContainer>
            <TextContainer>
              <CardTitle>Email Us</CardTitle>
              <CardDescription>
                Send us an email and we’ll get back to you.
              </CardDescription>
            </TextContainer>
          </ContactCard>

          <ContactCard onClick={handleCallClick}>
            <IconContainer>
              <FontAwesomeIcon icon={faPhoneAlt} size="2x" />
            </IconContainer>
            <TextContainer>
              <CardTitle>Call Us</CardTitle>
              <CardDescription>
                Reach us directly via phone for immediate assistance.
              </CardDescription>
            </TextContainer>
          </ContactCard>
        </CardContainer>
      </ContentWrapper>
    </Container>
  );
};

export default ContactPage;

// Styled components
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #1a1a1a;
  min-height: 100vh;
  color: #ffffff;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 600px;
  margin-top: -50px; /* Adjust this value to move the content up */
`;

const Logo = styled.img`
  width: 150px;
  margin-bottom: 30px;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
`;

const ContactCard = styled.div`
  background-color: #252525;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  padding: 20px;
  display: flex;
  align-items: center;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.8);
  }
`;

const IconContainer = styled.div`
  margin-right: 20px;
  color: #3fc48a;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const CardTitle = styled.h2`
  font-size: 20px;
  margin: 0;
  color: #3fc48a;
`;

const CardDescription = styled.p`
  font-size: 16px;
  margin: 5px 0 0;
  color: #ffffff;
`;
