import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { registerUser, fetchAndSaveUserDetails } from "../services/ApiService";
import boxmealLogoSrc from "../assets/images/logo.png";
import { useAuth } from "../context/AuthContext";

const RegistrationScreen = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [personalNumber, setPersonalNumber] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [fridgeName, setFridgeName] = useState("");
  const [errors, setErrors] = useState({});

  const { login } = useAuth();
  const navigate = useNavigate();

  const validateFields = () => {
    const newErrors = {};
    const personalNumberRegex = /^\d{12}$/;

    if (!firstName) newErrors.firstName = "Förnamn är obligatoriskt";
    if (!lastName) newErrors.lastName = "Efternamn är obligatoriskt";
    if (!personalNumber || !personalNumberRegex.test(personalNumber)) {
      newErrors.personalNumber =
        "Giltigt 12-siffrigt personnummer krävs (YYYYMMDDXXXX)";
    }
    if (!email) newErrors.email = "E-postadress är obligatorisk";
    if (!phone) newErrors.phone = "Telefonnummer är obligatoriskt";

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handlePersonalNumberChange = (e) => {
    const value = e.target.value.replace(/\D/g, ""); // Remove any non-digit characters
    setPersonalNumber(value);
  };

  const handleRegistration = async () => {
    if (!validateFields()) return;

    const userData = {
      firstName,
      lastName,
      personalNumber,
      email,
      phone,
      fridgeName,
      role: "user",
    };

    try {
      const result = await registerUser(userData);
      console.log("Registration result: ", result);

      if (result.success) {
        console.log("Registration successful, token:", result.token);

        // Fetch user details and log in the user
        const userDetails = await fetchAndSaveUserDetails(result.token);
        if (userDetails) {
          console.log("User details fetched: ", userDetails);
          login(userDetails, result.token);
          navigate("/products");
        } else {
          console.error("Failed to fetch user details.");
        }
      } else {
        console.error("Registration failed:", result.error);
      }
    } catch (error) {
      console.error("Error during registration:", error);
    }
  };

  return (
    <Container>
      <TopLogoContainer>
        <TopLogo src={boxmealLogoSrc} alt="Boxmeal Logo" />
      </TopLogoContainer>
      <Form>
        <Input
          type="text"
          placeholder="Förnamn"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          isInvalid={!!errors.firstName}
        />
        {errors.firstName && <Error>{errors.firstName}</Error>}

        <Input
          type="text"
          placeholder="Efternamn"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          isInvalid={!!errors.lastName}
        />
        {errors.lastName && <Error>{errors.lastName}</Error>}

        <Input
          type="text"
          placeholder="Personnummer (YYYYMMDDXXXX)"
          value={personalNumber}
          onChange={handlePersonalNumberChange}
          isInvalid={!!errors.personalNumber}
        />
        {errors.personalNumber && <Error>{errors.personalNumber}</Error>}

        <Input
          type="email"
          placeholder="E-postadress"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          isInvalid={!!errors.email}
        />
        {errors.email && <Error>{errors.email}</Error>}

        <Input
          type="tel"
          placeholder="Telefon"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          isInvalid={!!errors.phone}
        />
        {errors.phone && <Error>{errors.phone}</Error>}

        <Input
          type="text"
          placeholder="Enhet-ID"
          value={fridgeName}
          onChange={(e) => setFridgeName(e.target.value)}
        />
        <Button onClick={handleRegistration}>Registrera</Button>
      </Form>
    </Container>
  );
};

export default RegistrationScreen;

// Styled components...

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  background-color: #121212;
`;

const TopLogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
`;

const TopLogo = styled.img`
  width: 100px;
  height: 100px;
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  background-color: #1e1e1e;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  gap: 10px;
`;

const Input = styled.input`
  width: 100%;
  border: 1px solid ${(props) => (props.isInvalid ? "#ff4d4d" : "#555")};
  border-radius: 5px;
  padding: 12px;
  margin-bottom: 10px;
  font-size: 16px;
  color: white;
  background-color: #2c2c2c;

  ::placeholder {
    color: #aaa;
  }
`;

const Button = styled.button`
  background-color: #3fc48a;
  padding: 15px;
  border-radius: 5px;
  color: white;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 40px;

  &:hover {
    background-color: #3fc48a;
  }
`;

const Error = styled.div`
  color: #ff4d4d;
  font-size: 14px;
  margin-bottom: 10px;
`;
